<template>
  <img class="icon icon_black" :src="getImagePath(file)" />
</template>

<script>
export default {
  name: "Icon",
  props: ["file"],
  methods: {
    getImagePath: file => {
      return require("@/images/" + file);
    }
  }
};
</script>

<style lang="scss">
.icon {
  &_black {
    opacity: 0.85;
  }
}
</style>
