<template>
  <div class="recent">
    <div class="recent__box" v-if="state.favoritePlans.length > 0">
      <div class="recent__head">Obľúbené</div>
      <div class="recent__body">
        <div
          class="recent__item"
          v-for="(recent, index) in state.favoritePlans"
          :key="index" @click="loadFavorite(index)"
        >
          <div class="recent__label">
            <div class="recent__point recent__point_origin">{{recent.origin.name.primary}}</div>
            →
            <div class="recent__point recent__point_destination">{{recent.destination.name.primary}}</div>
          </div>
          <div :class="[
            'recent__favorite',
            isFavorite(recent) ? 'recent__favorite_true' : ''
          ]" @click.stop="toggleFavorite(recent)"></div>
        </div>
      </div>
    </div>

    <div class="recent__box" v-if="state.recentPlans.length > 0">
      <div class="recent__head">Posledné hľadané</div>
      <div class="recent__body">
        <div
          class="recent__item"
          v-for="(recent, index) in state.recentPlans.slice().reverse()"
          :key="index" @click="loadRecent(index)"
        >
          <div class="recent__label">
            <div class="recent__point recent__point_origin">{{recent.origin.name.primary}}</div>
            <div class="recent__arrow">→</div>
            <div class="recent__point recent__point_destination">{{recent.destination.name.primary}}</div>
          </div>
          <div :class="[
            'recent__favorite',
            isFavorite(recent) ? 'recent__favorite_true' : ''
          ]" @click.stop="toggleFavorite(recent)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Recent",
  props: {
    state: Object
  },
  methods: {
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    loadRecent(index) {
      var params = this.state.recentPlans[index];
      this.updateState('origin', params.origin);
      this.updateState('destination', params.destination);
    },
    loadFavorite(index) {
      var params = this.state.favoritePlans[index];
      this.updateState('origin', params.origin);
      this.updateState('destination', params.destination);
    },
    toggleFavorite(recent) {
      this.$emit('favoritePlan', JSON.stringify(recent));
    },
    isFavorite(recent) {
      // var result = this.state.favoritePlans.includes(recent);
      var stringified = JSON.stringify(recent);
      var favorite = false;
      this.state.favoritePlans.forEach(function(plan) {
        if (stringified == JSON.stringify(plan)) {
          favorite = true;
          return;
        }
      });

      return favorite;
    }
  }
};
</script>

<style lang="scss">
.recent {
  &__box {
    // box-shadow: inset 0 2rem 2rem -2rem $c-button-bg;
    // background-color: $c-white;
    padding: 1rem 1.25rem;
    border-radius: .5rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    
    :not(.page_dark) & {
        background-color: $c-white;
    }
    .page_dark & {
        background-color: $c-dark-gray;
    }
  }

  &__head {
    margin-bottom: .5rem;
    font-size: 1.1rem;
    font-weight: 700;
  }

  &__body {}

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid $c-border-gray;
    }
  }

  &__label {
    flex-grow: 1;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    padding: .65rem 0;
  }

  &__point {
    font-weight: 700;
    font-size: .85rem;

    &_origin {
      flex-basis: 100%;
    }

    &_destination {
      margin-left: .25rem;
    }
  }

  &__arrow {
    opacity: .5;
  }

  &__favorite {
    margin-left: 1rem;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    background-size: 1.5rem 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/favorite.svg);
    opacity: .45;

    &_true {
      background-image: url(../images/favorited.svg);
      opacity: 1;
    }
  }
}
</style>
