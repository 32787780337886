<template>
  <Page />
</template>

<script>
import Page from "./components/Page.vue";

export default {
  name: "App",
  components: {
    Page
  }
};
</script>

<style lang="scss">
// Base
img {
  display: block;
}
* {
  box-sizing: border-box;
}

// Global
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
