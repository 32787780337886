<template>
  <div :class="[
    'plan',
    JSON.stringify(state.plan) != '{}' ? 'plan_notempty' : ''
  ]">
    <div class="plan__head">
      <div v-if="loading" class="plan__loading">
        Hľadám spoje...
      </div>
      <div v-if="state.plan.empty" class="plan__empty">
        Zadaniu nevyhovujú žiadne spoje.
      </div>
    </div>
    <div class="plan__body">
      <Itinerary
        v-for="(itinerary, index) in state.plan.itineraries"
        :key="index"
        :itinerary="itinerary"
        :compact="index >= 3"
        :state="state"
        :saved="isSaved(itinerary)"
        :recommended="index == 0"
        @updateState="updateState"
        @focusItinerary="focusItinerary(index)"
        @updateFocus="updateFocus(index)"
        @closeSidebar="$emit('closeSidebar')"
        @saveItinerary="saveItinerary(index)"
        @unsaveItinerary="unsaveItinerary(index)"
      />
    </div>
  </div>
</template>

<script>
import Itinerary from "./Itinerary.vue";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "Plan",
  components: {
    Itinerary
  },
  props: {
    state: Object
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    saveItinerary(index) {
      var itinerary = JSON.stringify(this.state.plan.itineraries[index]);
      this.$emit("saveItinerary", itinerary);
    },
    unsaveItinerary(index) {
      this.$emit("unsaveItinerary", this.state.plan.itineraries[index]);
    },
    isSaved(itinerary) {
      var stringified = JSON.stringify(itinerary);
      var saved = false;
      this.state.savedItineraries.forEach(function(it) {
        if (JSON.stringify(it) == stringified) {
          saved = true;
          return;
        }
      });
      return saved;
    },
    focusItinerary(index) {
      this.updateFocus(index);
      this.updateState("focusedLeg", -1);
    },
    updateFocus(index) {
      this.updateState("focusSaved", false);
      this.updateState("focusedItinerary", index);
    },
    makeLatlng(stop) {
      return [stop.coords.lat, stop.coords.lng];
    },
    processPlan(plan) {
      var self = this;

      plan.itineraries.forEach(itinerary => {
        var legs = itinerary.legs;

        // Decode paths
        legs.forEach(leg => {
          leg.decodedPath = self.pathDecoder.decode(leg.path);
        });

        // Extend walking paths
        legs.forEach((leg, index) => {
          if (leg.mode == "walk") {
            if (index == 0) {
              leg.decodedPath.unshift(self.makeLatlng(leg.stops[0]));
            }

            if (index > 0) {
              let path = legs[index - 1].decodedPath;
              leg.decodedPath.unshift(path[path.length - 1]);
            }

            if (index < legs.length - 1) {
              let path = legs[index + 1].decodedPath;
              leg.decodedPath.push(path[0]);
            }

            if (index == legs.length - 1) {
              leg.decodedPath.push(self.makeLatlng(leg.stops[1]));
            }
          }
        });

        // Get itinerary bounds
        itinerary.bounds = new L.latLngBounds();
        legs.forEach(leg => {
          itinerary.bounds.extend(new L.latLngBounds(leg.decodedPath));
        });
      });

      return plan;
    }
  },
  mounted() {
    // Path decoder
    this.pathDecoder = require("polyline-encoded");
  },
  computed: {
    planParams: function() {
      return {
        origin: this.state.origin.coords,
        destination: this.state.destination.coords,
        arriveBy: this.state.arriveBy,
        date: this.state.date.value,
        maxTransfers: this.state.maxTransfers,
        maxWalkDistance: this.state.maxWalkDistance,
        walkSpeed: this.state.walkSpeed,
        optimize: this.state.optimize,
        time: this.state.time.now
          ? dayjs().format("H:mm")
          : this.state.time.hours +
            ":" +
            ("0" + this.state.time.minutes).slice(-2)
      };
    },
    planPoints: function() {
      return {
        origin: this.state.origin,
        destination: this.state.destination
      };
    }
  },
  watch: {
    planParams: {
      handler(newParams, oldParams) {
        // Prevent double request after point name update
        // console.log(JSON.stringify(newParams), JSON.stringify(oldParams));
        if (JSON.stringify(newParams) == JSON.stringify(oldParams)) {
          return;
        }

        this.updateState("plan", {});
        this.updateState("action", '');

        if (
          this.state.origin.coords.lat != 0 &&
          this.state.origin.coords.lng != 0 &&
          this.state.destination.coords.lat != 0 &&
          this.state.destination.coords.lng != 0
        ) {
          this.loading = true;

          axios
            .get("https://api.najdispoj.sk:25517/plan", {
              params: this.planParams
            })
            .then(response => {
              this.loading = false;

              var plan = Object.freeze(this.processPlan(response.data.plan));

              this.updateState("focusSaved", false);
              this.updateState("focusedLeg", -1);
              this.updateState("plan", plan);
              this.updateState("focusedItinerary", 0);
            });
        }
      },
      deep: true
    },
    planPoints: {
      handler: function() {
        if (
          this.state.origin.coords.lat != 0 &&
          this.state.origin.coords.lng != 0 &&
          this.state.origin.name.primary != '' &&
          this.state.destination.coords.lat != 0 &&
          this.state.destination.coords.lng != 0 &&
          this.state.destination.name.primary != ''
        ) {
          this.$emit('rememberPlan', JSON.stringify(this.planPoints));
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.plan {
  height: 100%;

  &_notempty {
    margin-bottom: 1rem;
  }

  &__head {
    font-size: 1.1rem;
    font-weight: 600;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.55;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    &:before {
      content: "";
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../images/loading.svg);
      opacity: 0.55;
      margin-right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;

      // Spinning animation
      animation-name: spin;
      animation-duration: 0.35s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &__empty {
    opacity: 0.55;
    text-align: center;
    padding-top: 1rem;
  }

  &__body {
  }
}
</style>
