<template>
    <div class="modal" @click="closeModal()">
        <div class="modal__overlay">
            <div class="modal__container">
                <div class="modal__box" @click.stop="">
                    <div class="modal__content" v-if="state.openModal == 'about'">
                        <div class="modal__heading">O najdispoj.sk</div>
                        <p>
                        Cieľom projektu NájdiSpoj.sk je sprehľadniť mestskú hromadnú dopravu v Bratislave tak, aby jej rozumel každý. Chceme, aby spoľahlivé informácie o najvhodnejšom dopravnom spoji boli vždy dostupné na dosah ruky.
                        </p>
                        <p>
                        Projekt je financovaný z nášho vrecka a venujeme sa mu vo voľnom čase. Veríme, že Bratislava si ako hlavné mesto podobnú službu zaslúži. Vyhľadávač sa snažíme neustále dolaďovať a obohacovať o nové užitočné funkcie.
                        </p>
                        <a href="https://www.facebook.com/najdispoj">facebook.com/najdispoj</a>
                    </div>

                    <div class="modal__content" v-if="state.openModal == 'sources'">
                        <div class="modal__heading">Zdroje dát</div>
                        Cestovné poriadky, informácie o linkách a vozidlách MHD poskytuje <a href="https://dpb.sk">Dopravný podnik Bratislava, a. s.</a>
                    </div>

                    <div class="modal__content" v-if="state.openModal == 'terms'">
                        <div class="modal__heading">Podmienky použitia</div>
                        <div class="popup-content">
                            <div><span style="line-height: 1.65em">
                            <h2>Všeobecné podmienky používania webovej aplikácie najdispoj.sk</h2>
                            <h3>1. Hlavné ustanovenia</h3>

                            <ol type="I">
                                <li>Predmetom úpravy týchto všeobecných podmienok je používanie aplikácie, resp. služby najdispoj.sk (ďalej len „NájdiSpoj“).</li>
                                <li>NájdiSpoj je webová aplikácia poskytujúca uživateľom informácie o cestovných poriadkoch verejnej dopravy a vyhľadávanie trás a liniek. NájdiSpoj ďalej poskytuje doplnkové informácie o jednotlivých spojoch, trasách, linkách a zástavkách verejnej dopravy.</li>
                                <li>Prevádzkovateľom aplikácie NájdiSpoj je „Stanislav Cingel - Cingel Design“ (ďalej len „prevádzkovateľ“).</li>
                                <li>Aplikácia NájdiSpoj použiva internetové stránky s adresou www.najdispoj.sk (ďalej len „internetové stránky NájdiSpoj“).</li>
                                <li>Uživateľom aplikácie NájdiSpoj je osoba pristupujúca na internetové stránky NájdiSpoj (ďalej len „užívateľ“).</li>
                                <li>Prevádzkovateľ si vyhradzuje právo tieto podmienky kedykoľvek meniť, a to bez akéhokoľvek upozornenia vopred.</li>
                                <li>Užívateľ bezprostredne súhlasí s týmito všeobecnými podmienkami, a je povinný si ich pred každou návštevou internetových stránok NájdiSpoj prezrieť.</li>
                            </ol>

                            <h3>2. Práva a povinnosti užívateľa</h3>

                            <ol type="I">
                                <li>Užívateľ berie na vedomie, že všetky informácie poskytnuté aplikáciou NájdiSpoj sú len informatívne a prevádzkovateľ nezodpovedá za ich aktuálnosť, úplnosť, správnosť a presnosť. Informácie o aktuálnosti cestovných poriadkov sú zobrazené v sekcií „O najdispoj.sk“.</li>
                                <li>Informácie sú užívateľovi poskytnuté výhradne na osobné účely. Užívateľ nesmie bez súhlasu prevádzkovateľa publikovať, rozmnožovať, distribuovať, poskytovať za úplatu a rozširovať žiadne materiály zverejnené na internetových stránkach NájdiSpoj, s výnimkou zdieľania skrátených URL adries, resp. QR kódov pomocou funkcie „Share“ resp. „Zdieľaj“.</li>
                                <li>Aplikácia NájdiSpoj je určená pre užívateľa ako koncového užívateľa, a užívateľ nie je oprávnený používať NájdiSpoj iným spôsobom, ako je uvedené v týchto všeobecných podmienkach.</li>
                                <li>Aplikácia NájdiSpoj nie je určená na komerčné použitie.</li>
                                <li>Užívateľ súhlasí s anonymným zberom dát používania aplikácie NájdiSpoj.</li>
                            </ol>

                            <h3>3. Zodpovednosť poskytovateľa</h3>

                            <ol type="I">
                                <li>Prevádzkovateľ nepreberá zodpovednosť za:
                                <ol type="a">
                                    <li>aktuálnosť, úplnosť, správnosť a presnosť informácií,</li>
                                    <li>nefunkčnosť počas technickej údržby alebo aktualizovania zverejnených informácií,</li>
                                    <li>nefunkčnosť spôsobenú technickými problémami tretej strany,</li>
                                    <li>dostupnosť služieb, a vyhradzuje si právo obmedziť alebo zrušiť užívateľovi prístup,</li>
                                    <li>priame i nepriame škody akejkoľvek povahy, spôsobené používaním aplikácie NájdiSpoj, jej výpadkom, alebo ukončením jej činnosti,</li>
                                    <li>príspevky, fotografie alebo materiály od používateľov alebo tretích strán,</li>
                                    <li>obsah stránok tretích strán, na ktoré vedú odkazy z internetových stránok NájdiSpoj,</li>
                                    <li>konanie užívateľov.</li>
                                </ol></li>
                            </ol>

                            <h3>4. Duševné vlastníctvo</h3>

                            <ol type="I">
                                <li>Prevádzkovateľ disponuje výhradnou licenciou podľa zákona 618/2003 Z.z. o autorskom práve a právach súvisiacich s autorským právom (autorský zákon).</li>
                                <li>Práva prevádzkovateľa sú uznávané a chránené príslušnými slovenskými a medzinárodnými zákonmi.</li>
                                <li>Užívateľ nesmie žiadnym spôsobom zasiahnuť do práv prevádzkovateľa a užívať ho iným spôsobom, ako je určené.</li>
                            </ol>

                            <h3>5. Záverečne ustanovenia</h3>

                            <ol type="I">
                                <li>Tieto všeobecné podmienky sa riadia slovenským právom a zákonmi.</li>
                                <li>Adresa elektronickej pošty prevádzkovateľa je: najdispoj@najdispoj.sk</li>
                            </ol>
                            </span>
                            </div>
                        </div>
                    </div>

                    <div class="modal__content" v-if="state.openModal == 'ticket'">
                        <div class="modal__heading">SMS cestovný lístok</div>
                        <div class="modal__disclaimer">
                            Pred odoslaním SMS si na nasledujúcom odkaze overte, či sú nižšie uvedené informácie aktuálne platné.
                            <a class="modal__ticket-info" href="https://dpb.sk/sk/sms-listok">Aktuálne informácie o SMS lístkoch na dpb.sk</a>
                            Zakúpený cestovný lístok nájdete v zozname prijatých SMS správ na Vašom zariadení. NájdiSpoj.sk nezodpovedá za správnosť údajov uvedených na tejto stránke, ktorá slúži iba ako jednoduché presmerovanie do SMS aplikácie.
                        </div>
                        <div class="modal__tickets">
                            <div class="modal__ticket">
                                <a class="ticket" href="sms:1140">
                                    <div class="ticket__heading">SMS cestovný lístok</div>
                                    <div class="ticket__zones">
                                        <div class="ticket__zones-label">Zóny</div>
                                        <div class="ticket__zones-value">100+101</div>
                                    </div>
                                    <div class="ticket__price">
                                        <div class="ticket__price-label">Cena</div>
                                        <div class="ticket__price-value">1,00 €</div>
                                    </div>
                                    <div class="ticket__validity">
                                        <div class="ticket__validity-label">Platnosť</div>
                                        <div class="ticket__validity-value">40 minút</div>
                                    </div>
                                    <div class="ticket__button">Poslať SMS</div>
                                </a>
                            </div>
                            <div class="modal__ticket">
                                <a class="ticket" href="sms:1100">
                                    <div class="ticket__heading">SMS cestovný lístok</div>
                                    <div class="ticket__zones">
                                        <div class="ticket__zones-label">Zóny</div>
                                        <div class="ticket__zones-value">100+101</div>
                                    </div>
                                    <div class="ticket__price">
                                        <div class="ticket__price-label">Cena</div>
                                        <div class="ticket__price-value">1,30 €</div>
                                    </div>
                                    <div class="ticket__validity">
                                        <div class="ticket__validity-label">Platnosť</div>
                                        <div class="ticket__validity-value">70 minút</div>
                                    </div>
                                    <div class="ticket__button">Poslať SMS</div>
                                </a>
                            </div>
                            <div class="modal__ticket">
                                <a class="ticket" href="sms:1124">
                                    <div class="ticket__heading">SMS cestovný lístok</div>
                                    <div class="ticket__zones">
                                        <div class="ticket__zones-label">Zóny</div>
                                        <div class="ticket__zones-value">100+101</div>
                                    </div>
                                    <div class="ticket__price">
                                        <div class="ticket__price-label">Cena</div>
                                        <div class="ticket__price-value">4,50 €</div>
                                    </div>
                                    <div class="ticket__validity">
                                        <div class="ticket__validity-label">Platnosť</div>
                                        <div class="ticket__validity-value">24 hodín</div>
                                    </div>
                                    <div class="ticket__button">Poslať SMS</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        'state': Object
    },
    methods: {
        updateState(key, value) {
            this.$emit("updateState", key, value);
        },
        closeModal() {
            this.updateState('openModal', '');
        },
    }
}
</script>

<style lang="scss">
    .modal {
        display: block;
        height: 100%;

        &__overlay {
            background-color: rgba($c-black, .5);
            height: 100%;
            padding: 2rem;
        }

        &__container {
            height: 100%;
            
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__box {
            border-radius: .5rem;
            max-height: 100%;
            max-width: 600px;
            overflow: auto;

            :not(.page_dark) & {
                background-color: $c-white;
            }
            .page_dark & {
                background-color: $c-black;
            }
        }

        &__content {
            padding: 1.5rem 2rem;
        }

        &__heading {
            font-size: 1.75rem;
            font-weight: 700;
            margin-bottom: .65rem;
        }

        &__disclaimer {
            margin-bottom: 1.5rem;
        }

        &__ticket-info {
            @include a-unstyled($c-green);

            display: block;
            margin-bottom: 1rem;
        }

        &__tickets {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 -.5rem;

            @media (max-width: $breakpoint-mobile) {
                flex-direction: column;
            }
        }

        &__ticket {
            display: block;
            padding: 0 .5rem;
            flex-shrink: 0;
            flex-basis: 33.33%;
            margin-bottom: 1rem;

            @media (max-width: 800px) {
                flex-basis: 50%;
            }
        }
    }

    .ticket {
        @include a-unstyled;

        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        padding: .65rem .75rem;
        border: 2px solid $c-green;
        border-radius: .65rem;
        width: 100%;

        &__heading {
            margin-bottom: .75rem;
            padding-bottom: .5rem;
            font-size: .75rem;
            font-weight: 700;
            border-bottom: 1px solid $c-border-gray;
            flex-basis: 100%;
        }

        &__zones {
            margin-bottom: .65rem;
            flex-grow: 1;
        }

        &__zones-label {
            font-size: .75rem;
            opacity: .75;
        }

        &__zones-value {
            font-weight: 600;
        }

        &__price {
            margin-bottom: .65rem;
        }

        &__price-label {
            font-size: .75rem;
            opacity: .75;
        }

        &__price-value {
            font-weight: 600;
        }

        &__validity {
            flex-basis: 100%;
            margin-bottom: .75rem;
        }

        &__validity-label {
            font-size: .75rem;
            opacity: .75;
        }

        &__validity-value {
            font-size: 1.5rem;
            font-weight: 600;
        }

        &__button {
            background-color: $c-green;
            padding: .65rem;
            border-radius: .5rem;
            font-size: .85rem;
            font-weight: 700;
            text-align: center;
            flex-basis: 100%;

            :not(.page_dark) & {
                color: $c-white;
            }
            .page_dark & {
                color: $c-black;
            }
        }
    }
</style>