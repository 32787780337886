<template>
  <div class="map">
    <div class="map__element" id="map"></div>
    <div v-if="hint != ''" class="map__hint-container">
      <div class="map__hint">
        {{ hint }}
      </div>
    </div>
    <div class="map__hidden" id="hidden-popup"></div>
    <Popup
      :target="popupTarget"
      @selectOrigin="selectOrigin"
      @selectDestination="selectDestination"
    />
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import "leaflet/dist/leaflet";
import "leaflet/dist/leaflet.css";
// import 'leaflet-doubletapdrag';
// import 'leaflet-doubletapdragzoom';

export default {
  name: "Map",
  emits: ["updateState", "openSidebar"],
  components: {
    Popup
  },
  props: {
    state: Object
  },
  data() {
    return {
      popupTarget: "#hidden-popup",
      canFireEvent: true
    };
  },
  computed: {
    focus: function() {
      var f = {
        focusedItinerary: this.state.focusedItinerary,
        focusedLeg: this.state.focusedLeg,
        focusTrigger: this.state.focusTrigger,
        focusSaved: this.state.focusSaved
      };
      return f;
    },
    hint: function() {
      if (this.state.action == "selectOriginFromMap") {
        return "Zvoľte štart kliknutím na ľubovoľné miesto na mape.";
      } else if (this.state.action == "selectDestinationFromMap") {
        return "Zvoľte cieľ kliknutím na ľubovoľné miesto na mape.";
      } else {
        return "";
      }
    }
  },
  methods: {
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    initIcons() {
      this.icons = {
        originIcon: L.icon({
          iconUrl: require("@/images/origin-black-bordered.svg"),
          iconSize: [42, 42],
          iconAnchor: [6, 42]
        }),
        destinationIcon: L.icon({
          iconUrl: require("@/images/destination-black-bordered.svg"),
          iconSize: [42, 42],
          iconAnchor: [6, 42]
        }),
        stopPrimaryIcon: L.icon({
          iconUrl: require("@/images/stop-primary.svg"),
          iconSize: [12, 12]
        }),
        stopSecondaryIcon: L.icon({
          iconUrl: require("@/images/stop-secondary.svg"),
          iconSize: [11, 11]
        }),
        positionIcon: L.icon({
          iconUrl: require("@/images/position.svg"),
          iconSize: [24, 24]
        })
      };
    },
    initMarkers() {
      var self = this;

      this.originMarker = L.marker([0, 0], {
        icon: this.icons.originIcon,
        draggable: true,
        autoPan: true
      }).bindTooltip("Štart", {
        direction: "bottom",
        opacity: 1
      });
      this.originMarker.on("dragend", function() {
        self.stopEvents();
        var point = {
          coords: self.originMarker.getLatLng(),
          name: {
            primary: "",
            secondary: ""
          }
        };
        self.updateState("origin", point);
      });

      this.destinationMarker = L.marker([0, 0], {
        icon: this.icons.destinationIcon,
        draggable: true,
        autoPan: true
      }).bindTooltip("Cieľ", {
        direction: "bottom",
        opacity: 1
      });
      this.destinationMarker.on("dragend", function() {
        self.stopEvents();
        var point = {
          coords: self.destinationMarker.getLatLng(),
          name: {
            primary: "",
            secondary: ""
          }
        };
        self.updateState("destination", point);
      });

      this.positionMarker = L.marker([0, 0], {
        icon: this.icons.positionIcon
      }).bindTooltip("Vaša poloha", {
        direction: "bottom",
        opacity: 1
      });
      this.positionRadius = L.circle([0, 0], {
        radius: 0,
        weight: 1,
        opacity: 0.5,
        color: "#077fcf",
        fillColor: "#077fcf",
        fillOpacity: 0.25
      });
    },

    initMap() {
      this.map = L.map("map", {
        zoomSnap: 0,
        zoomControl: false
      });
      this.map.setView([48.1625, 17.1225], 13.5);

      // Controls
      new L.Control.Zoom({
        position: "bottomright"
      }).addTo(this.map);
      new L.Control.Scale({
        maxWidth: 150,
        imperial: false
      }).addTo(this.map);

      this.map.on("click", this.onClick);
      this.map.on("dblclick", this.onDblclick);

      // TODO check licenses and add more tile layers from:
      // https://leaflet-extras.github.io/leaflet-providers/preview/

      var OpenStreetMap_Mapnik = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 19,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // detectRetina: true
        }
      ).addTo(this.map);

      // Popup
      this.popup = L.popup({
        // className: 'popup'
      });

      // Icons
      this.initIcons();

      // Markers
      this.initMarkers();

      // Colors
      this.colors = {
        walk: "#4caf50",
        bus: "#077fcf",
        trolley: "#eeb600",
        tram: "#c20000"
      };

      this.darkColors = {
        walk: "#ffffff",
        bus: "#0462a1",
        trolley: "#c49600",
        tram: "#830000"
      };
    },

    resetItinerary() {
      if (this.polylines) {
        this.polylines.forEach(function(p) {
          p.remove();
        });
      }

      if (this.whitePolylines) {
        this.whitePolylines.forEach(function(p) {
          p.remove();
        });
      }

      if (this.markers) {
        this.markers.forEach(function(m) {
          m.remove();
        });
      }

      this.polylines = [];
      this.whitePolylines = [];
      this.markers = [];
    },

    makeLatlng(stop) {
      return [stop.coords.lat, stop.coords.lng];
    },

    displayItinerary(itinerary) {
      this.resetItinerary();
      var self = this;

      // Draw polylines
      itinerary.legs.forEach(leg => {
        leg.bounds = new L.latLngBounds(leg.decodedPath);

        var polyline;
        polyline = L.polyline(leg.decodedPath, {
          color: this.darkColors[leg.mode],
          weight: 12,
          dashArray: leg.mode == "walk" ? "0 12" : null
        })
          .bindTooltip(leg.mode == "walk" ? "Peší presun" : leg.number, {
            direction: "top",
            sticky: true,
            opacity: 1
          })
          .addTo(this.map);

        this.whitePolylines.push(polyline);

        polyline = L.polyline(leg.decodedPath, {
          color: this.colors[leg.mode],
          weight: 7,
          dashArray: leg.mode == "walk" ? "0 12" : null,
          interactive: false
        }).addTo(this.map);

        this.polylines.push(polyline);
      });

      // Draw stops
      itinerary.legs.forEach(leg => {
        if (leg.mode != "walk") {
          leg.stops.forEach((s, index) => {
            var primary = index == 0 || index == leg.stops.length - 1;

            var coords;
            if (index == 0) {
              coords = leg.decodedPath[0];
            } else if (index == leg.stops.length - 1) {
              coords = leg.decodedPath[leg.decodedPath.length - 1];
            } else {
              coords = this.makeLatlng(s);
            }

            var marker = L.marker(coords, {
              icon: primary
                ? self.icons.stopPrimaryIcon
                : self.icons.stopSecondaryIcon
            })
              .bindTooltip(s.name, {
                direction: "top",
                opacity: 1
              })
              .addTo(self.map);

            self.markers.push(marker);
          });
        }
      });
    },

    focusItinerary() {
      // Pan to itinerary/leg
      var bounds, b;
      if (this.state.focusedLeg == -1) {
        // Pan to itinerary
        if (this.state.focusSaved) {
          b = this.state.savedItineraries[this.state.focusedItinerary].bounds;
          bounds = L.latLngBounds(b._northEast, b._southWest);
        } else {
          bounds = this.state.plan.itineraries[this.state.focusedItinerary]
            .bounds;
        }
        this.map.flyToBounds(bounds, {
          padding: [50, 50],
          duration: 0.75
        });
      } else {
        // Pan to leg
        if (this.state.focusSaved) {
          b = this.state.savedItineraries[this.state.focusedItinerary].legs[
            this.state.focusedLeg
          ].bounds;
          bounds = L.latLngBounds(b._northEast, b._southWest);
        } else {
          bounds = this.state.plan.itineraries[this.state.focusedItinerary]
            .legs[this.state.focusedLeg].bounds;
        }
        this.map.flyToBounds(bounds, {
          padding: [50, 50],
          duration: 0.75
        });
      }
    },

    onClick(e) {
      if (!this.canFireEvent) {
        return;
      }

      this.stopEvents();

      var self = this;

      setTimeout(function() {
        if (!self.canFireEvent) {
          return;
        }

        if (self.state.action == "selectOriginFromMap") {
          let point = {
            coords: e.latlng,
            name: {
              primary: "",
              secondary: ""
            }
          };
          self.updateState("origin", point);
          self.$emit("openSidebar");
          self.state.action = "selectedPointFromMap";
          return;
        } else if (self.state.action == "selectDestinationFromMap") {
          let point = {
            coords: e.latlng,
            name: {
              primary: "",
              secondary: ""
            }
          };
          self.updateState("destination", point);
          self.$emit("openSidebar");
          self.state.action = "selectedPointFromMap";
          return;
        }

        self.popupTarget = ".leaflet-popup-content";
        self.popupCoords = e.latlng;
        self.popup.setLatLng(e.latlng).openOn(self.map);

        self.$nextTick(() => {
          // Adjust width after DOM is loaded
          self.popup.update();
        });
      }, 201);
    },

    onDblclick() {
      this.stopEvents();
    },

    selectOrigin() {
      var point = {
        coords: {
          lat: this.popupCoords.lat,
          lng: this.popupCoords.lng
        },
        name: {
          primary: "",
          secondary: ""
        }
      };
      this.updateState("origin", point);
      this.map.closePopup();
    },
    selectDestination() {
      var point = {
        coords: {
          lat: this.popupCoords.lat,
          lng: this.popupCoords.lng
        },
        name: {
          primary: "",
          secondary: ""
        }
      };
      this.updateState("destination", point);
      this.map.closePopup();
    },
    stopEvents() {
      var self = this;

      self.canFireEvent = false;

      clearTimeout(self.timeout);

      this.timeout = setTimeout(function() {
        self.canFireEvent = true;
      }, 200);
    }
  },
  watch: {
    state: {
      handler() {
        this.resetItinerary();

        // Origin
        if (
          this.state.origin.coords.lat != 0 &&
          this.state.origin.coords.lng != 0
        ) {
          this.originMarker.setLatLng(this.state.origin.coords);
          this.originMarker.addTo(this.map);
        } else {
          this.originMarker.remove();
        }

        // Destination
        if (
          this.state.destination.coords.lat != 0 &&
          this.state.destination.coords.lng != 0
        ) {
          this.destinationMarker.setLatLng(this.state.destination.coords);
          this.destinationMarker.addTo(this.map);
        } else {
          this.destinationMarker.remove();
        }

        // Position
        if (
          this.state.position.coords.lat != 0 &&
          this.state.position.coords.lng != 0 &&
          this.state.position.radius < 200
        ) {
          this.positionRadius.setLatLng(this.state.position.coords);
          this.positionRadius.setRadius(this.state.position.radius);
          this.positionRadius.addTo(this.map);
          this.positionMarker.setLatLng(this.state.position.coords);
          this.positionMarker.addTo(this.map);
        } else {
          this.positionMarker.remove();
          this.positionRadius.remove();
        }

        if (this.state.focusSaved) {
          if (
            this.state.savedItineraries &&
            this.state.savedItineraries.length > 0 &&
            this.state.savedItineraries.length > this.state.focusedItinerary
          ) {
            this.displayItinerary(
              this.state.savedItineraries[this.state.focusedItinerary]
            );
          }
        } else {
          if (
            this.state.plan != {} &&
            this.state.plan.itineraries &&
            this.state.plan.itineraries.length > 0
          ) {
            this.displayItinerary(
              this.state.plan.itineraries[this.state.focusedItinerary]
            );
          }
        }
      },
      deep: true
    },
    focus: {
      handler() {
        if (this.state.focusSaved) {
          if (
            this.state.savedItineraries &&
            this.state.savedItineraries.length > 0
          ) {
            this.focusItinerary();
          }
        } else {
          if (
            this.state.plan != {} &&
            this.state.plan.itineraries &&
            this.state.plan.itineraries.length > 0
          ) {
            this.focusItinerary();
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initMap();
  }
};
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 100%;
  overscroll-behavior: contain;
  position: relative;

  &__hint-container {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  &__hint {
    background-color: $c-white;
    border-radius: 0.5rem;
    padding: 1rem 1.25rem;
    font-weight: 600;
    box-shadow: 0 0 0.75rem -0.1rem rgba($c-black, 0.5);
  }

  &__element {
    height: 100%;
    z-index: 0;

    :not(.page_dark) & {
      background-color: #f2efe9;
    }
    .page_dark & {
      background-color: $c-dark-gray;
    }
  }

  &__hidden {
    display: none;
  }
}

.leaflet-container {
  font-family: $font-family;
}

.leaflet-tile-pane {
  // filter: grayscale(0.25) contrast(0.75) brightness(1.15);
  // Experimental dark mode
  .page_dark & {
    filter: invert(1) hue-rotate(200deg) grayscale(0.45);
  }
}

.leaflet-tooltip {
  font-weight: 800;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  // box-shadow: none;
  z-index: -1;
  position: relative;

  .page_plastic & {
    border-bottom: 2px solid $c-light-gray-side;
  }

  &:after {
    $arrow-size: 12px;

    content: "";
    display: block;
    position: absolute;
    top: calc(100% - 1px);
    left: calc(50% - #{$arrow-size});
    border-top: $arrow-size solid $c-white;
    border-left: $arrow-size solid transparent;
    border-right: $arrow-size solid transparent;

    :not(.page_dark) & {
      border-top-color: $c-white;
    }
    .page_dark & {
      border-top-color: $c-black;
    }
  }
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-tip-container {
  display: none;
}

// .leaflet-popup-tip {
//   box-shadow: none;
//   z-index: 10;
// }

.leaflet-popup-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem !important;
  height: 1.75rem !important;
  border-radius: 100px;
  padding: 0.15rem !important;
  top: -0.6rem !important;
  right: -0.6rem !important;
  // z-index: 10;
  font-size: 1rem !important;

  :not(.page_dark) & {
    color: rgba($c-black, 0.55) !important;
    background-color: $c-white !important;
  }
  .page_dark & {
    color: rgba($c-white, 0.55) !important;
    background-color: $c-black !important;
  }
}
</style>
