<template>
  <div :class="['advanced', open ? 'advanced_open' : '']">
    <div class="advanced__body">
      <div class="advanced__content">
        <div class="advanced__category">
          <div class="advanced__radio">
            <label class="advanced__label" tabindex="0">
              <input
                type="radio"
                name="searchfor"
                v-model="optimize"
                value="transfers"
              />
              <div class="advanced__option">Menej prestupov</div>
            </label>
            <label class="advanced__label" tabindex="0">
              <input
                type="radio"
                name="searchfor"
                v-model="optimize"
                value="quick"
              />
              <div class="advanced__option">Krátke trvanie</div>
            </label>
          </div>
        </div>

        <div class="advanced__category">
          <div class="advanced__top">
            <div class="advanced__heading">Max. počet prestupov:</div>
            <div class="advanced__value">{{ this.maxTransfers }}</div>
          </div>
          <vue-slider
            v-model="maxTransfers"
            :height="2"
            :min="0"
            :max="5"
            :step="1"
            :adsorb="true"
            :tooltip="'none'"
            :dragOnClick="true"
            @drag-end="updateTransfers()"
          ></vue-slider>
        </div>

        <div class="advanced__category">
          <div class="advanced__top">
            <div class="advanced__heading">Max. pešia vzdialenosť:</div>
            <div class="advanced__value">{{ walkDistanceStr }}</div>
          </div>
          <vue-slider
            v-model="maxWalkDistance"
            :height="2"
            :min="0"
            :max="5000"
            :interval="100"
            :adsorb="true"
            :tooltip="'none'"
            :dragOnClick="true"
            @drag-end="updateWalkDistance()"
          ></vue-slider>
        </div>

        <div class="advanced__category">
          <div class="advanced__top">
            <div class="advanced__heading">Rýchlosť kráčania:</div>
            <div class="advanced__value">{{ walkSpeedStr }}</div>
          </div>
          <vue-slider
            v-model="walkSpeed"
            :height="2"
            :min="0"
            :max="3"
            :step="1"
            :adsorb="true"
            :tooltip="'none'"
            :dragOnClick="true"
            @drag-end="updateWalkSpeed()"
          ></vue-slider>
        </div>
      </div>
    </div>
    <div @click="toggleOpen()" class="advanced__head" tabindex="0">
      <div class="advanced__arrow">
        <Icon file="angle-down-black.svg" />
      </div>
      <div class="advanced__title">
        Rozšírené nastavenia
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: "Advanced",
  components: {
    Icon,
    VueSlider
  },
  props: {
    state: Object
  },
  data() {
    return {
      open: false,
      maxTransfers: this.state.maxTransfers,
      maxWalkDistance: this.state.maxWalkDistance,
      walkSpeed: this.state.walkSpeed,
      optimize: this.state.optimize,

      walkSpeedLabels: {
        0: "Prechádzka",
        1: "Chôdza",
        2: "Poklus",
        3: "Šprint!"
      }
    };
  },
  watch: {
    optimize: function() {
      this.updateState("optimize", this.optimize);
    }
  },
  computed: {
    walkDistanceStr: function() {
      if (this.maxWalkDistance < 1000) {
        return this.maxWalkDistance + " m";
      } else {
        return Math.floor(this.maxWalkDistance / 100) / 10 + " km";
      }
    },
    walkSpeedStr: function() {
      return this.walkSpeedLabels[this.walkSpeed];
    }
  },
  methods: {
    updateTransfers() {
      this.updateState("maxTransfers", this.maxTransfers);
    },

    updateWalkDistance() {
      this.updateState("maxWalkDistance", this.maxWalkDistance);
    },

    updateWalkSpeed() {
      this.updateState("walkSpeed", this.walkSpeed);
    },

    updateState(key, value) {
      this.$emit("updateState", key, value);
    },

    toggleOpen() {
      this.open = !this.open;
    }
  }
};
</script>

<style lang="scss">
.advanced {
  &__body {
    position: relative;
    font-size: 0.85rem;
    font-weight: 600;
    max-height: 0;
    transition: max-height 0.2s ease-in-out;
    overflow: hidden;

    // border-top: 1px solid $c-light-gray;
    :not(.page_dark) & {
      border-color: $c-light-gray;
    }
    .page_dark & {
      border-color: $c-black;
    }

    .advanced_open & {
      max-height: 300px;
    }
  }

  &__content {
    padding: 1.5rem 2.5rem;
    padding-top: 1rem;
    border-radius: 0.5rem;
  }

  &__category {
    &:first-child {
      margin-bottom: 2rem;
    }

    &:not(:last-child):not(:first-child) {
      margin-bottom: 1rem;
    }
  }

  &__top {
    display: flex;
    // justify-content: space-between;
  }

  &__heading {
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    font-weight: 400;
  }

  &__value {
    font-weight: 800;
  }

  &__radio {
    display: flex;
  }

  &__label {
    display: flex;
    cursor: pointer;

    &:first-child {
      margin-right: 1rem;
    }

    input {
      display: none;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    // opacity: .85;

    &:before {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../images/option.svg);
      margin-right: 0.35rem;
      // opacity: .25;
    }

    input[type="radio"]:checked + &:before {
      background-image: url(../images/option-selected.svg);
      // opacity: .75;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    cursor: pointer;

    border-top: 1px solid $c-light-gray;
    :not(.page_dark) & {
      border-top-color: $c-light-gray;
    }
    .page_dark & {
      border-top-color: $c-black;
    }
  }

  &__arrow {
    border-radius: 100px;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    padding-top: 0.4rem;
    margin-right: 1rem;

    :not(.page_dark) & {
      background-color: $c-light-gray;
    }
    .page_dark & {
      background-color: $c-black;
    }

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }

    img {
      width: 1rem;
      opacity: 0.55;
      transition: 0.2s transform ease-in-out;

      .page_dark & {
        filter: invert(1);
      }

      .advanced_open & {
        transform: rotate(180deg);
      }
    }
  }

  &__title {
    font-weight: 600;
    opacity: 0.75;
  }
}

.vue-slider {
  overscroll-behavior: contain;
  touch-action: none;
}

.vue-slider-rail {
  :not(.page_dark) & {
    background-color: $c-light-gray !important;
  }
  .page_dark & {
    background-color: $c-black !important;
  }
}

.vue-slider-process {
  background-color: $c-blue !important;
}

.vue-slider-dot-handle-focus {
  box-shadow: none;
}

.vue-slider-dot-handle {
  background-color: $c-blue;
  border-color: $c-blue !important;
}
</style>
