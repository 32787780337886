<template>
  <div class="header">
    <a class="header__link" href="/">
      <div class="header__logo"><img src="@/images/logo.svg" /></div>
      <div class="header__titles">
        <div class="header__title">najdispoj.sk</div>
        <div class="header__subtitle">MHD Bratislava</div>
      </div>
    </a>
  </div>
</template>

<script></script>

<style lang="scss">
.header {
  display: flex;
  justify-content: center;
  padding: 1.75rem 1.25rem;
  z-index: 10;
  position: relative;

  :not(.page_dark) & {
    background-color: $c-light-gray;
  }
  .page_dark & {
    background-color: $c-black;
  }

  &__link {
    @include a-unstyled;

    display: flex;
    // align-items: center;
    text-decoration: none;
    position: relative;
  }

  &__logo {
    padding-right: 1.15rem;

    .page__body_dark & {
      border-color: $c-white;
    }

    img {
      height: 3.75rem;
      // filter: drop-shadow(0 0 0.25rem rgba($c-red, .35));
    }
  }

  &__titles {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-left: 1.25rem;
    // border-left: 1px solid $c-border-gray;
  }

  &__title {
    font-size: 1.55rem;
    font-weight: 700;
    opacity: 0.85;
    // color: $c-red;
    line-height: 1.55em;
  }

  &__subtitle {
    opacity: 0.65;
    font-size: 0.65rem;
    line-height: 0.8rem;
    // color: $c-blue;
    font-weight: 400;
    padding-left: 0.5px;
  }
}
</style>
