<template>
  <div
    :class="['page', state.darkmode ? 'page_dark' : '']"
    @click="trigger('popupClose')"
  >
    <div
      :class="[
        'page__side',
        !open ? 'page__side_hidden' : '',
        sliding ? 'page__side_sliding' : '',
        touch ? 'page__side_touch' : ''
      ]"
      @touchstart="touchStart($event)"
      @touchmove="touchMove($event)"
      @touchend="touchEnd()"
      :style="[sidebarOffset != 0 ? { left: sidebarOffset + 'px' } : {}]"
    >
      <div class="page__sidebar">
        <Sidebar
          :state="state"
          @updateState="updateState"
          :triggers="triggers"
          @swapPoints="swapPoints()"
          @closeSidebar="closeSidebar()"
          @saveItinerary="saveItinerary"
          @clearSaved="clearSaved()"
          @unsaveItinerary="unsaveItinerary"
          @beginGeolocation="beginGeolocation()"
          @rememberPlan="rememberPlan"
          @favoritePlan="favoritePlan"
        />
      </div>
      <div class="page__slider" @click="toggleSidebar()">
        <div class="page__arrow">
          <img src="@/images/doublearrow-left-white.svg" />
        </div>
      </div>
    </div>
    <div class="page__map">
      <Map
        :state="state"
        @updateState="updateState"
        @openSidebar="openSidebar()"
      />
    </div>
    <div :class="[
      'page__modal',
      modalOpen ? 'page__modal_open' : ''
    ]">
      <Modal
        :state="state"
        @updateState="updateState"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import Map from "./Map.vue";
import Modal from "./Modal.vue";
import dayjs from "dayjs";

import SimpleBar from "simplebar";
import "simplebar/dist/simplebar.css";

export default {
  name: 'Page',
  components: {
    Sidebar,
    Map,
    Modal
  },
  data() {
    return {
      // Sliding sidebar
      open: true,
      panStartX: 0,
      panStartY: 0,
      panCurrentX: 0,
      panCurrentY: 0,
      sidebarOffsetStart: 0,
      sidebarOffset: 0,
      sidebarWidth: 0,
      offsetX: 0,
      offsetY: 0,
      sliding: false,
      scrolling: false,
      touch: false,
      skipTouch: false,

      triggers: {
        popupClose: false
      },

      state: {
        origin: {
          coords: {
            lat: 0,
            lng: 0
          },
          name: {
            primary: "",
            secondary: ""
          }
        },
        destination: {
          coords: {
            lat: 0,
            lng: 0
          },
          name: {
            primary: "",
            secondary: ""
          }
        },
        position: {
          coords: {
            lat: 0,
            lng: 0
          },
          radius: 0,
          name: {
            primary: "",
            secondary: ""
          }
        },
        date: {
          value: "",
          date: dayjs(),
          name: {
            primary: ""
          }
        },
        time: {
          hours: 0,
          minutes: 0,
          now: true
        },
        arriveBy: false,
        action: "",

        maxTransfers: 3,
        maxWalkDistance: 2500,
        walkSpeed: 1,
        optimize: "transfers",

        plan: {},
        focusedItinerary: 0,
        focusedLeg: -1, // -1 = none,
        focusTrigger: false, // used to trigger state watcher in Map
        supportsGeolocation: false,

        darkmode: false,

        savedItineraries: this.initSavedItineraries(),
        recentPlans: this.initRecentPlans(),
        favoritePlans: this.initFavoritePlans(),
        focusSaved: false,

        openModal: "",
      }
    };
  },
  computed: {
    modalOpen: function() {
      return this.state.openModal != "";
    },
  },
  watch: {
    state: {
      handler: function() {
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute("content", this.state.darkmode ? "#000000" : "#ffffff");
      },
      deep: true
    }
  },
  mounted() {
    if (window.matchMedia("(min-width: 500px)").matches) {
      new SimpleBar(document.getElementsByClassName("page__sidebar")[0], {
        autoHide: false
      });
    }

    this.checkPermissions();

    if (this.state.supportsGeolocation && this.state.allowGeolocation) {
      this.beginGeolocation();
    }

    this.initLocalStorage();
  },
  methods: {
    updateState(key, value) {
      // console.log(key, value);
      this.state[key] = value;

      if (
        key == "focusedItinerary" ||
        key == "focusedLeg" ||
        key == "focusSaved"
      ) {
        this.state.focusTrigger = !this.state.focusTrigger;
      }
    },

    trigger(t) {
      var newTriggers = JSON.parse(JSON.stringify(this.triggers));
      newTriggers[t] = !newTriggers[t];

      this.triggers = newTriggers;
    },

    swapPoints() {
      var temp = this.state["origin"];
      this.state["origin"] = this.state["destination"];
      this.state["destination"] = temp;
    },

    toggleSidebar() {
      this.open = !this.open;
    },

    openSidebar() {
      this.open = true;
    },

    closeSidebar() {
      this.open = false;
      this.updateState("focusedLeg", -1);
    },

    touchStart(event) {
      var self = this;

      event.path.forEach(function(el) {
        if (el.classList && el.classList.contains("vue-slider")) {
          self.skipTouch = true;
        }
      });

      if (this.skipTouch) {
        return;
      }

      this.sidebarWidth = document.getElementsByClassName(
        "page__sidebar"
      )[0].width;
      this.sidebarOffsetStart = document.getElementsByClassName(
        "page__side"
      )[0].offsetLeft;
      this.panStartX = event.touches[0].clientX;
      this.panStartY = event.touches[0].clientY;
      this.touch = true;

      this.sidebarOffset = this.sidebarOffsetStart;
    },

    touchMove(event) {
      if (this.skipTouch) {
        return;
      }

      this.panCurrentX = event.touches[0].clientX;
      this.panCurrentY = event.touches[0].clientY;

      this.offsetX = this.panCurrentX - this.panStartX;
      this.offsetY = this.panCurrentY - this.panStartY;

      if (!this.sliding && !this.scrolling) {
        if (Math.abs(this.offsetY) < 20 && Math.abs(this.offsetX) > 20) {
          this.sliding = true;
        } else if (Math.abs(this.offsetY) > 20 && Math.abs(this.offsetX) < 20) {
          this.scrolling = true;
        }
      }

      if (this.sliding) {
        this.sidebarOffset = this.sidebarOffsetStart + this.offsetX;

        if (this.sidebarOffset < this.sidebarWidth) {
          this.sidebarOffset = this.sidebarWidth;
        } else if (this.sidebarOffset > 0) {
          this.sidebarOffset = this.sidebarWidth;
        }
      } else {
        this.sidebarOffset = this.sidebarOffsetStart;
      }
    },

    touchEnd() {
      if (this.skipTouch) {
        this.skipTouch = false;
        return;
      }

      if (this.offsetX < -30 && this.sliding) {
        this.open = false;
      } else if (this.offsetX > 30 && this.sliding) {
        this.open = true;
      }
      this.sidebarOffset = 0;
      this.sliding = false;
      this.scrolling = false;
      this.touch = false;
    },

    checkPermissions() {
      if (navigator.geolocation) {
        this.state.supportsGeolocation = true;
      }

      if (localStorage.allowGeolocation == "true") {
        this.state.allowGeolocation = true;
      }
    },

    geolocate() {
      var self = this;
      navigator.geolocation.watchPosition(function(position) {
        self.updateState("allowGeolocation", true);
        localStorage.allowGeolocation = "true";

        self.updateState("position", {
          coords: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          radius: position.coords.accuracy,
          name: {
            primary: "Vaša poloha",
            secondary: ""
          }
        });
      });
    },

    beginGeolocation() {
      this.geolocate();
      // setInterval(this.geolocate, 1000);
    },

    initSavedItineraries() {
      if (localStorage.itineraries) {
        var parsed = JSON.parse(localStorage.itineraries);
        return Object.freeze(parsed);
      } else {
        return [];
      }
    },

    initRecentPlans() {
      if (localStorage.recentPlans) {
        var parsed = JSON.parse(localStorage.recentPlans);
        return Object.freeze(parsed);
      } else {
        return [];
      }
    },

    initFavoritePlans() {
      if (localStorage.favoritePlans) {
        var parsed = JSON.parse(localStorage.favoritePlans);
        return Object.freeze(parsed);
      } else {
        return [];
      }
    },

    initLocalStorage() {
      if (!localStorage.itineraries) {
        localStorage.itineraries = "[]";
      }
      if (!localStorage.recentPlans) {
        localStorage.recentPlans = "[]";
      }
      if (!localStorage.favoritePlans) {
        localStorage.favoritePlans = "[]";
      }
    },

    rememberPlan(planParams) {
      var plans = JSON.parse(localStorage.favoritePlans);
      var isFavorite = false;
      plans.forEach(function(params) {
        if (JSON.stringify(params) == planParams) {
          isFavorite = true;
          return;
        }
      });

      if (isFavorite) {
        return;
      }

      var plans = JSON.parse(localStorage.recentPlans);
      var isRecent = false;
      plans.forEach(function(params) {
        if (JSON.stringify(params) == planParams) {
          isRecent = true;
          return;
        }
      });

      if (isRecent) {
        return;
      }

      plans.push(JSON.parse(planParams));
      plans = plans.slice(Math.max(plans.length - 5, 0))

      localStorage.recentPlans = JSON.stringify(plans);
      this.updateState("recentPlans", this.initRecentPlans());
    },

    favoritePlan(planParams) {
      // Toggle - favorite/unfavorite
      var isFavorite = false;
      var plans = JSON.parse(localStorage.favoritePlans);
      var newPlans = [];
      plans.forEach(function(params) {
        if (JSON.stringify(params) == planParams) {
          isFavorite = true;
        } else {
          newPlans.push(params);
        }
      });

      if (isFavorite) {
        // Remove from favorites
        localStorage.favoritePlans = JSON.stringify(newPlans);
      } else {
        // Add to favorites
        plans.push(JSON.parse(planParams));
        localStorage.favoritePlans = JSON.stringify(plans);
      }

      this.updateState("favoritePlans", this.initFavoritePlans());
    },

    saveItinerary(itinerary) {
      var skip = false;
      var itineraries = JSON.parse(localStorage.itineraries);
      itineraries.forEach(function(it) {
        if (JSON.stringify(it) == itinerary) {
          skip = true;
          return;
        }
      });

      if (skip) {
        return;
      }

      itineraries.push(JSON.parse(itinerary));

      localStorage.itineraries = JSON.stringify(itineraries);
      this.updateState("savedItineraries", this.initSavedItineraries());
    },

    unsaveItinerary(itinerary) {
      var stringified = JSON.stringify(itinerary);
      var itineraries = JSON.parse(localStorage.itineraries);
      itineraries = itineraries.filter(it => {
        return JSON.stringify(it) != stringified;
      });

      localStorage.itineraries = JSON.stringify(itineraries);
      this.updateState("savedItineraries", this.initSavedItineraries());
    },

    clearSaved() {
      localStorage.itineraries = "[]";
      this.updateState("savedItineraries", this.initSavedItineraries());
    }
  }
};
</script>

<style lang="scss">
.page {
  font-family: $font-family;
  color: $c-font-black;
  display: flex;
  position: relative;
  height: 100%;
  max-height: 100%;

  &_dark {
    color: $c-white;
  }

  &__side {
    z-index: 1;
    width: 385px;
    height: 100%;

    @media (max-width: $breakpoint-mobile) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      transition: left 0.35s ease-out;

      &_sliding,
      &_touch {
        transition: none;
      }

      &_hidden {
        left: calc(-100% + 30px);
      }
    }
  }

  &__sidebar {
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 0.75rem -0.1rem rgba($c-black, 0.5);

    @media (max-width: $breakpoint-mobile) {
      width: calc(100% - 30px);
      box-shadow: 0 0 2.5rem 1.25rem rgba($c-black, 0.5);
    }
  }

  &__slider {
    display: none;
    position: absolute;
    left: calc(100% - 30px);
    width: 60px;
    height: 100%;

    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
  }

  &__arrow {
    position: relative;
    height: 100%;
    width: 30px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: absolute;
      width: 16px;
      left: 7px;
      transition: transform 0.35s ease-in-out;

      .page__side_hidden & {
        transform: rotate(180deg);
      }
    }
  }

  &__map {
    flex-grow: 1;
    z-index: 0;
    position: relative;
    touch-action: none;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: none;

    &_open {
      display: block;
    }
  }
}
</style>
