<template>
  <div class="sidebar" :style="{ 'min-height': height + 'px' }">
    <div class="sidebar__header">
      <Header />
    </div>
    <div class="sidebar__options">
      <Options
        :state="state"
        :triggers="triggers"
        @updateState="updateState"
        @swapPoints="$emit('swapPoints')"
        @closeSidebar="$emit('closeSidebar')"
        @clearSaved="$emit('clearSaved')"
        @unsaveItinerary="
          it => {
            $emit('unsaveItinerary', it);
          }
        "
      />
    </div>
    <div class="sidebar__content">
      <div
        class="sidebar__allowgeo"
        v-if="state.supportsGeolocation && !state.allowGeolocation"
      >
        Pre zobrazenie vašej polohy na mape je potrebné vaše povolenie.
        <div class="sidebar__allowgeo-button" @click="allowGeolocation()">
          Povoliť prístup k polohe
        </div>
      </div>

      <Plan
        :state="state"
        @updateState="updateState"
        @closeSidebar="$emit('closeSidebar')"
        @saveItinerary="saveItinerary"
        @rememberPlan="params => {
          $emit('rememberPlan', params);
        }"
        @unsaveItinerary="it => {
          $emit('unsaveItinerary', it);
        }
        "
      />

      <Recent
        :state="state" @updateState="updateState"
        @favoritePlan="plan => {
          $emit('favoritePlan', plan);
        }"
      />
    </div>
    <div class="sidebar__footer">
      <Footer :state="state" @updateState="updateState" />
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Options from "./Options.vue";
import Footer from "./Footer.vue";
import Recent from "./Recent.vue";
import Plan from "./Plan.vue";

export default {
  components: {
    Header,
    Options,
    Footer,
    Recent,
    Plan
  },
  props: {
    state: Object,
    triggers: Object
  },
  data() {
    return {
      height: window.innerHeight
    };
  },
  mounted() {
    window.onresize = () => {
      this.height = window.innerHeight;
    };
  },
  methods: {
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    saveItinerary(itinerary) {
      this.$emit("saveItinerary", itinerary);
    },
    allowGeolocation() {
      this.$emit("beginGeolocation");
    }
  }
};
</script>

<style lang="scss">
.sidebar {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;

  :not(.page_dark) & {
    background-color: $c-white;
  }
  .page_dark & {
    background-color: $c-black;
  }

  input {
    font-family: inherit;
  }

  .page__body_dark & {
    background-color: $c-black;
  }

  &__header {
  }

  &__logo {
  }

  &__options {
  }

  &__content {
    flex-grow: 1;
    padding: 1rem 1.25rem;

    :not(.page_dark) & {
      background-color: $c-light-gray;
    }
    .page_dark & {
      background-color: $c-black;
    }
  }

  &__allowgeo {
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // font-size: .9rem;
    text-align: center;

    :not(.page_dark) & {
      background-color: $c-white;
    }
    .page_dark & {
      background-color: $c-dark-gray;
    }
  }

  &__allowgeo-button {
    margin-top: 1rem;
    display: flex;
    padding: 0.65rem 0.85rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 0.85rem;
    font-weight: 700;
    opacity: 0.85;
    color: $c-green;

    :not(.page_dark) & {
      background-color: $c-green-light;
    }
    .page_dark & {
      background-color: $c-green-dark;
    }
  }

  &__recent {
    flex-grow: 1;
  }

  &__footer {
  }
}
</style>
