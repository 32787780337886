<template>
  <div class="saved">
    <div v-if="state.savedItineraries.length == 0" class="saved__empty">
      Momentálne nemáte uložené žiadne spoje.
    </div>
    <div v-else>
      <div class="saved__buttons">
        <div
          class="saved__button saved__button_clear"
          tabindex="0"
          @click="$emit('clearSaved')"
        >
          Odstrániť všetky
        </div>
      </div>
      <Itinerary
        v-for="(itinerary, index) in state.savedItineraries"
        :key="index"
        :itinerary="itinerary"
        :saved="true"
        :recommended="false"
        :compact="false"
        :state="state"
        @updateState="updateState"
        @focusItinerary="focusItinerary(index)"
        @updateFocus="updateFocus(index)"
        @closeSidebar="$emit('closeSidebar')"
        @unsaveItinerary="unsaveItinerary(index)"
      />
    </div>
  </div>
</template>
<script>
import Itinerary from "./Itinerary.vue";

export default {
  name: "Saved",
  components: {
    Itinerary
  },
  props: ["state"],
  methods: {
    focusItinerary(index) {
      this.updateFocus(index);
      this.updateState("focusedLeg", -1);
    },
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    updateFocus(index) {
      this.updateState("focusSaved", true);
      this.updateState("focusedItinerary", index);
    },

    unsaveItinerary(index) {
      this.$emit("unsaveItinerary", this.state.savedItineraries[index]);
    }
  }
};
</script>

<style lang="scss">
.saved {
  padding: 1rem 1.25rem;

  &__empty {
    text-align: center;
    padding: 0.5rem 0;
    font-weight: 600;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  &__button {
    // flex-grow: 1;
    display: flex;
    padding: 0.65rem 0.85rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 0.85rem;
    font-weight: 700;
    opacity: 0.85;

    &_clear {
      color: $c-red;

      :not(.page_dark) & {
        background-color: $c-red-light;
      }
      .page_dark & {
        background-color: $c-red-dark;
      }
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
