<template>
  <div
    :class="[
      'itinerary',
      compact ? 'itinerary_compact' : '',
      recommended ? 'itinerary_recommended' : '',
      mounted ? 'itinerary_mounted' : '',
      open ? 'itinerary_open' : ''
    ]"
    @click="toggleOpen()"
  >
    <div class="itinerary__head">
      <div class="itinerary__time">
        <div class="itinerary__timespan">
          <div class="itinerary__time-from">
            {{ timeFormat(itinerary.departure) }}
          </div>
          <div class="itinerary__time-to">
            {{ timeFormat(itinerary.arrival) }}
          </div>
        </div>
        <div class="itinerary__time-duration">
          {{ durationFormat(itinerary.duration) }}
        </div>
      </div>
      <div v-show="departureIn != ''" class="itinerary__departure">{{departureIn}}</div>
      <Timeline :itinerary="itinerary" />
    </div>
    <div class="itinerary__body">
      <div class="itinerary__legs">
        <Leg
          v-for="(leg, index) in itinerary.legs"
          :leg="leg"
          :key="index"
          @click="onClick(index)"
          @closeSidebar="$emit('closeSidebar')"
        />
      </div>
      <div class="itinerary__buttons">
        <a
          v-if="!saved"
          class="itinerary__button itinerary__button_green"
          @click.stop="$emit('saveItinerary')"
          >Uložiť</a
        >
        <a
          v-if="saved"
          class="itinerary__button itinerary__button_red"
          @click.stop="$emit('unsaveItinerary')"
          >Odstrániť</a
        >
        <div class="itinerary__button" @click.stop="focusItinerary()">
          Zobraziť na mape
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Leg from "./Leg.vue";
import Timeline from "./Timeline.vue";

export default {
  name: "Itinerary",
  components: {
    Leg,
    Timeline
  },
  props: {
    state: Object,
    recommended: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    itinerary: Object,
    saved: Boolean
  },
  data() {
    return {
      mounted: false,
      open: false,
      now: dayjs(),
      departureIn: this.getDepartureIn(),
    };
  },
  methods: {
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    toggleOpen() {
      this.open = !this.open;
      this.$emit("focusItinerary");
    },
    timeFormat(timestamp) {
      return dayjs(timestamp).format("H:mm");
    },
    durationFormat(duration, genitive = false) {
      duration = Math.ceil(duration / 60); // Round up just to be sure
      if (duration == 1) {
        return genitive ? "1 minútu" : "1 minúta";
      } else if (duration >= 2 && duration <= 4) {
        return duration + " minúty";
      } else {
        return duration + " minút";
      }
    },
    focusItinerary() {
      this.$emit("focusItinerary");
      this.$emit("closeSidebar");
    },
    onClick(index) {
      this.$emit("updateFocus");
      this.updateState("focusedLeg", index);
    },
    getDepartureIn() {
      var difference = Math.floor((this.itinerary.departure - dayjs().valueOf()) / 1000);
      if (difference > 86400) {
        // > 24 hours
        return 'odchod ' + dayjs(this.itinerary.departure).format('D.M.YYYY');
      }
      if (difference > 3600) {
        // > 1 hour
        var hours = Math.floor(difference / 3600);
        var seconds = difference % 3600;
        return 'odchod za ' + hours + ' h ' + Math.floor(seconds/60) + ' min ';
      }
      return difference > 0 ? 'odchod za ' + this.durationFormat( difference, true ) : '';
    }
  },
  mounted() {
    this.mounted = true;
    if (this.recommended) {
      this.open = true;
    }

    var self = this;
    this.timer = setInterval(function() {
      self.departureIn = self.getDepartureIn();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss">
.itinerary {
  border-radius: 0.5rem;
  position: relative;

  :not(.page_dark) & {
    background-color: $c-white;
  }
  .page_dark & {
    background-color: $c-dark-gray;
  }

  .page_plastic & {
    border-bottom: 2px solid $c-light-gray-side;
  }

  .options__page & {
    :not(.page_dark) & {
      border: 1px solid $c-border-gray;
    }
    .page_dark & {
      border: 1px solid $c-black;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0.65rem;
    height: 0.75rem;
    width: 1rem;
    background-size: 2rem 0.5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/angle-down-black.svg);
    opacity: 0.25;
    pointer-events: none;
    left: calc(50% - 0.5rem);
  }

  &_open {
    &:after {
      display: none;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 1.25rem;
    cursor: pointer;

    .itinerary_compact & {
      padding-bottom: 2.15rem;
    }

    .itinerary_open & {
      padding-bottom: 1rem;
    }
  }

  &__recommended {
    display: none;
    flex-basis: 100%;
    font-size: 0.85rem;
    color: $c-blue;
    font-weight: 700;
    margin-bottom: 1rem;

    .itinerary_recommended & {
      display: block;
    }
  }

  &__time {
  }

  &__timespan {
    display: flex;
    font-weight: 700;
    font-size: 1.15rem;
  }

  &__time-from {
    display: flex;

    &:after {
      content: "-";
      display: block;
      margin: 0 0.25rem;
    }
  }

  &__time-to {
    margin-right: 0.25rem;
  }

  &__time-duration {
    opacity: 0.45;
    font-weight: 600;
    flex-basis: 100%;
  }

  &__departure {
    // font-size: 1.15rem;
    margin-top: .15rem;
    // font-weight: 300;
    opacity: .65;
  }

  &__ratings {
    flex-basis: 45%;
    font-size: 0.85rem;
  }

  &__rating {
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 0.15rem;
    }

    &_good {
      color: $c-green;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        background-image: url(../images/check.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 0.25rem;
      }
    }
  }

  &__body {
    border-top: 1px solid $c-light-gray;
    padding-bottom: 1rem;

    :not(.page_dark) & {
      border-top-color: $c-light-gray;
    }
    .page_dark & {
      border-top-color: $c-black;
    }

    .itinerary_open & {
      padding-bottom: 0;
    }

    .itinerary_compact & {
      display: none;
    }

    .itinerary_compact.itinerary_open & {
      display: block;
    }
  }

  &__legs {
    margin-left: -0.85rem;
    margin-bottom: -4px; // Negative .leg margin bottom
    pointer-events: none;
    padding: 1rem 1.25rem;

    .itinerary_open & {
      pointer-events: auto;
    }
  }

  &__buttons {
    display: none;
    justify-content: flex-end;
    padding: 1rem 1.25rem;
    border-top: 1px solid $c-light-gray;

    :not(.page_dark) & {
      border-top-color: $c-light-gray;
    }
    .page_dark & {
      border-top-color: $c-black;
    }

    .itinerary_open & {
      display: flex;
    }
  }

  &__button {
    // flex-grow: 1;
    color: $c-blue;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    font-weight: 700;
    font-size: 0.85rem;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    :not(.page_dark) & {
      background-color: $c-blue-light;
    }
    .page_dark & {
      background-color: $c-blue-dark;
    }

    .page_plastic & {
      border-bottom: 2px solid $c-blue-light-side;
    }

    &_green {
      color: $c-green;
      border-color: $c-green-light-side !important;

      :not(.page_dark) & {
        background-color: $c-green-light;
      }
      .page_dark & {
        background-color: $c-green-dark;
      }
    }

    &_red {
      color: $c-red;

      :not(.page_dark) & {
        background-color: $c-red-light;
      }
      .page_dark & {
        background-color: $c-red-dark;
      }
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
