<template>
  <div :class="['leg', 'leg_' + leg.mode]" @click.stop="toggleOpen()">
    <div class="leg__label">{{ leg.number }}</div>
    <div v-if="leg.mode == 'walk'" class="leg__info">
      <div class="leg__name">
        Peší presun
        <span class="leg__distance">({{ distanceFormat(leg.distance) }})</span>
      </div>
      <div class="leg__duration">{{ durationFormat(leg.duration) }}</div>
      <div v-if="leg.waitingDuration" class="leg__waiting">
        Čakanie {{ durationFormat(leg.waitingDuration) }}
      </div>
    </div>
    <div v-else class="leg__details">
      <div class="leg__stop">
        <div class="leg__stop-name">{{ leg.stops[0].name }}</div>
        <div class="leg__stop-time leg__stop-time_departure">
          {{ timeFormat(leg.stops[0].departure) }}
        </div>
      </div>
      <Intermediate :open="this.intermediateOpen" :stops="leg.stops" />
      <div class="leg__stop">
        <div class="leg__stop-name">
          {{ leg.stops[leg.stops.length - 1].name }}
        </div>
        <div class="leg__stop-time">
          {{ timeFormat(leg.stops[leg.stops.length - 1].departure) }}
        </div>
      </div>
      <div v-if="leg.waitingDuration" class="leg__waiting">
        Čakanie {{ durationFormat(leg.waitingDuration, false) }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Intermediate from "./Intermediate.vue";

export default {
  name: "Leg",
  components: {
    Intermediate
  },
  props: {
    leg: Object
  },
  data() {
    return {
      intermediateOpen: false
    };
  },
  methods: {
    toggleOpen() {
      this.intermediateOpen = !this.intermediateOpen;
      if (this.leg.mode == "walk") {
        this.$emit("closeSidebar");
      }
    },
    timeFormat(timestamp) {
      return dayjs(timestamp).format("H:mm");
    },
    distanceFormat(distance) {
      if (distance < 1000) {
        return Math.floor(distance) + " m";
      } else {
        return Math.floor(distance / 100) / 10 + " km";
      }
    },
    durationFormat(duration, roundUp = true) {
      if (roundUp) {
        duration = Math.ceil(duration / 60); // Round up just to be sure
      } else {
        duration = Math.floor(duration / 60);
      }

      if (duration == 0) {
        duration = 1;
      }

      if (duration == 1) {
        return "1 minúta";
      } else if (duration >= 2 && duration <= 4) {
        return duration + " minúty";
      } else {
        return duration + " minút";
      }
    }
  }
};
</script>

<style lang="scss">
.leg {
  display: flex;
  cursor: pointer;

  margin-bottom: 4px;

  &_walk {
    overflow: hidden;
    max-height: 0;
    margin-bottom: 0;
    transition: max-height 0.2s ease-in-out, margin-bottom 0.2s ease-in-out;

    .itinerary_open &,
    &:first-child:last-child {
      max-height: 60px;
      margin-bottom: 4px;
    }
  }
  &_bus {
  }
  &_tram {
  }
  &_trolley {
  }

  &__label {
    flex-basis: 15%;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 0.85rem;
    line-height: 1em;
    padding: 0.7rem 0;

    .leg_walk & {
      color: $c-green;
    }
    .leg_bus & {
      color: $c-blue;
    }
    .leg_tram & {
      color: $c-red;
    }
    .leg_trolley & {
      color: $c-yellow;
    }

    &:before {
      content: "";
      display: block;
      margin-bottom: 0.25rem;
      width: 1.15rem;
      height: 1.75rem;
      background-size: contain;
      background-position: bottom center;
      background-repeat: no-repeat;

      .leg_walk & {
        background-image: url(../images/walk.svg);
      }
      .leg_bus & {
        background-image: url(../images/bus.svg);
      }
      .leg_tram & {
        background-image: url(../images/tram.svg);
      }
      .leg_trolley & {
        background-image: url(../images/trolley.svg);
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      left: 100%;
      top: 0;
      width: 5px;
      border-radius: 100px;

      .leg_walk & {
        // width: 0;
        // border-right: 5px dotted $c-green;
        background-size: contain;
        background-image: url(../images/walk-bg-vert.svg);
      }
      .leg_bus & {
        background-color: $c-blue;
      }
      .leg_tram & {
        background-color: $c-red;
      }
      .leg_trolley & {
        background-color: $c-yellow;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    padding-left: 1.25rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 0;
    transition: padding 0.2s ease-in-out;
  }

  &__name {
    font-size: 0.85rem;
    font-weight: 700;
  }

  &__distance {
    font-weight: 400;
  }

  &__duration {
    opacity: 0.75;
    font-size: 0.85rem;
  }

  &__waiting {
    flex-basis: 100%;
    font-size: 0.75rem;
    opacity: 0.85;
    margin-top: 0.25rem;
  }

  &__stop {
    display: flex;
    // justify-content: space-between;
    font-size: 0.95rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__stop-name {
    font-weight: 700;
    flex-grow: 1;
    display: flex;
    align-items: baseline;
    // font-size: .95rem;

    &:after {
      content: "";
      display: block;
      flex-grow: 1;
      padding-bottom: 10px;
      border-bottom: 2px dotted #ccc;
      margin: 0 0.5rem;
    }
  }

  &__stop-time {
    opacity: 0.75;

    &_departure {
      font-weight: 700;
      opacity: 1;
    }
  }
}
</style>
