<template>
  <div class="options">
    <div class="options__tabs">
      <div
        :class="[
          'options__tab',
          activeTab == 'search' ? 'options__tab_active' : ''
        ]"
        @click="selectTab('search')"
      >
        Vyhľadávač
      </div>

      <div
        :class="[
          'options__tab',
          activeTab == 'saved' ? 'options__tab_active' : ''
        ]"
        @click="selectTab('saved')"
      >
        Uložené
        <div v-if="state.savedItineraries.length > 0" class="options__num">
          {{ state.savedItineraries.length }}
        </div>
      </div>
    </div>
    <div class="options__pages">
      <div
        :class="[
          'options__page',
          activeTab == 'search' ? 'options__page_active' : ''
        ]"
      >
        <div class="options__filter">
          <div class="options__category">
            <div class="options__points">
              <div class="options__point options__point_origin">
                <div class="options__label">
                  <div class="options__icon">
                    <Icon file="origin-black.svg" />
                  </div>
                  <div class="options__name">Štart</div>
                </div>
                <div class="options__content">
                  <Point
                    :point="state.origin"
                    :origin="true"
                    :state="state"
                    @updateState="updateState"
                    @updatePoint="updateState('origin', $event)"
                    @pressedEnter="focusDestination()"
                    @selectFromMap="
                      $emit('closeSidebar');
                      updateState('action', 'selectOriginFromMap');
                    "
                  />
                </div>
              </div>

              <div class="options__swap">
                <div class="options__swap-wrapper">
                  <div
                    class="options__swap-button"
                    @click="$emit('swapPoints')"
                    tabindex="0"
                  >
                    <Icon file="swap-black.svg" />
                  </div>
                </div>
              </div>

              <div class="options__point options__point_destination">
                <div class="options__label">
                  <div class="options__icon">
                    <Icon file="destination-black.svg" />
                  </div>
                  <div class="options__name">Cieľ</div>
                </div>
                <div class="options__content">
                  <Point
                    :point="state.destination"
                    :origin="false"
                    :state="state"
                    @updatePoint="updateState('destination', $event)"
                    @pressedEnter="focusDatetime()"
                    @selectFromMap="
                      $emit('closeSidebar');
                      updateState('action', 'selectDestinationFromMap');
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="options__category">
            <!-- <div class="options__label"></div> -->
            <div class="options__content">
              <Datetime
                :state="state"
                @updateState="updateState"
                :triggers="triggers"
              />
            </div>
          </div>
        </div>
        <Advanced :state="state" @updateState="updateState" />
        <div class="options__divider options__divider_active"></div>
      </div>
      <div
        :class="[
          'options__page',
          activeTab == 'saved' ? 'options__page_active' : ''
        ]"
      >
        <Saved
          :state="state"
          @updateState="updateState"
          @clearSaved="$emit('clearSaved')"
          @closeSidebar="$emit('closeSidebar')"
          @unsaveItinerary="it => $emit('unsaveItinerary', it)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import Point from "./Point.vue";
import Datetime from "./Datetime.vue";
import Advanced from "./Advanced.vue";
import Saved from "./Saved.vue";

export default {
  components: {
    Icon,
    Point,
    Datetime,
    Advanced,
    Saved
  },
  props: {
    state: Object,
    triggers: Object
  },
  data() {
    return {
      options: {},
      activeTab: "search"
    };
  },
  methods: {
    selectTab(id) {
      this.activeTab = id;
    },
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    focusDestination() {
      document.getElementsByClassName("point__input")[1].focus();
    },
    focusDatetime() {
      document.getElementsByClassName("datetime__arriveby")[0].focus();
    }
  }
};
</script>

<style lang="scss">
.options {
  // border-bottom-left-radius: .75rem;
  // border-bottom-right-radius: .75rem;

  :not(.page_dark) & {
    background-color: $c-light-gray;
  }
  .page_dark & {
    background-color: $c-black;
  }

  .page_plastic & {
    border-bottom: 2px solid $c-light-gray-side;
  }

  &__tabs {
    display: flex;
    // border-bottom: 1px solid $c-border-gray;
    padding: 0 1.25rem;
  }

  &__tab {
    flex-grow: 1;
    flex-basis: 33.33%;
    text-align: center;
    // border: 1px solid rgba($c-border-gray, 0);
    border-bottom: 1px solid rgba($c-border-gray, 0);
    padding: 0.75rem 1rem;
    padding-bottom: 0.65rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;

    :not(.page_dark) & {
      color: rgba($c-font-black, 0.35);
    }
    .page_dark & {
      color: rgba($c-white, 0.35);
    }

    &:not(:first-child) {
      margin-left: -2px;
    }

    &_active {
      border-color: $c-border-gray;
      border-bottom: none;

      :not(.page_dark) & {
        background-color: $c-white;
        color: rgba($c-font-black, 0.75);
      }
      .page_dark & {
        background-color: $c-dark-gray;
        color: rgba($c-white, 0.85);
      }
    }
  }

  &__num {
    margin-left: 0.45rem;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    font-size: 0.75rem;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    :not(.page_dark) & {
      background-color: lighten($c-blue, 25);
      color: $c-white;
    }
    .page_dark & {
      background-color: lighten($c-blue, 25);
      color: $c-black;
    }
  }

  &__page {
    display: none;

    :not(.page_dark) & {
      background-color: $c-white;
    }
    .page_dark & {
      background-color: $c-dark-gray;
    }

    &_active {
      display: block;
    }
  }

  &__filter {
    padding: 1rem 1.25rem;
    position: relative;
    z-index: 1;
  }

  &__category {
    display: flex;
    align-items: center;
  }

  &__points {
    width: 100%;
  }

  &__point {
    display: flex;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 10%;
    padding-top: 0.35rem;
    // padding: .35rem 0;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &__icon {
    margin-bottom: 0.35rem;
    // padding: 0 0.5rem;

    img {
      width: 1.65rem;

      .page_dark .options__point_destination & {
        filter: invert(1) brightness(0.5);
      }
    }
  }

  &__name {
    font-size: 0.75rem;
    font-weight: 600;
    opacity: 0.75;
    line-height: 1em;
    // text-align: center;
  }

  &__content {
    flex-grow: 1;
  }

  &__swap {
    position: relative;
    display: flex;
    // height: 0;
  }

  &__swap-wrapper {
    // similar to options__label for now
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 10%;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &__swap-button {
    flex-basis: 10%;
    flex-shrink: 0;
    position: absolute;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    border-radius: 100px;
    top: -3.25rem;
    cursor: pointer;
    // border: 2px solid $c-border-gray;

    :not(.page_dark) & {
      background-color: $c-button-bg;
    }
    .page_dark & {
      background-color: $c-black;
    }

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }

    img {
      width: 1rem;
      opacity: 0.55;

      .page_dark & {
        filter: invert(1);
        opacity: 0.25;
      }
    }
  }

  &__divider {
    $divider-size: 1.25rem;

    // height: 2px;
    background-color: $c-border-gray;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 1px;
    margin-top: -1.5px;
    z-index: 100;

    // &:before {
    //   content: "";
    //   display: block;
    //   width: 0;
    //   border-left: $divider-size solid transparent;
    //   border-right: $divider-size solid transparent;
    //   border-top: $divider-size solid $c-border-gray;
    //   position: absolute;
    // }

    // &:after {
    //   content: "";
    //   display: block;
    //   width: 0;
    //   margin-top: -3px;
    //   border-left: $divider-size solid transparent;
    //   border-right: $divider-size solid transparent;
    //   border-top: $divider-size solid $c-white;
    //   position: absolute;
    // }

    &:before,
    &:after {
      top: -1rem;
      transition: top 0.2s ease-in-out;
    }

    &_active {
      &:before,
      &:after {
        top: 0;
      }
    }
  }
}
</style>
