<template>
  <div class="timeline">
    <div
      class="timeline__container"
      :style="{ width: itinerary.timelineDuration + '%' }"
    >
      <div
        v-for="(leg, index) in itinerary.legs"
        :class="['timeline__leg', 'timeline__leg_' + leg.mode]"
        :key="index"
        :style="{ width: leg.timelineDuration + '%' }"
      >
        <div v-if="leg.mode != 'walk'" class="timeline__number">
          {{ leg.number }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    itinerary: Object
  }
};
</script>

<style lang="scss">
.timeline {
  width: 0;
  transition: width 0.5s ease-in-out;
  margin-top: 1.25rem;

  .itinerary_mounted & {
    width: 100%;
  }

  &__container {
    display: flex;
  }

  &__leg {
    text-align: center;
    font-weight: 700;
    font-size: 0.75;
    position: relative;

    &:not(&_walk) {
      min-width: 2.25rem;
    }

    &:not(:last-child) {
      margin-right: 2px;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      top: 100%;
      height: 3px;
      border-radius: 100px;
    }

    &_walk {
      &:after {
        background-image: url(../images/walk-bg.svg);
        background-size: contain;
      }
    }

    &_bus {
      color: $c-blue;
      &:after {
        background-color: $c-blue;
      }
    }

    &_trolley {
      color: $c-yellow;
      &:after {
        background-color: $c-yellow;
      }
    }

    &_tram {
      color: $c-red;
      &:after {
        background-color: $c-red;
      }
    }
  }

  &__number {
    font-size: 0.75rem;
    line-height: 1.5em;
    position: absolute;
    bottom: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: baseline;

    &:before {
      content: "";
      display: block;
      // margin-bottom: .25rem;
      width: 0.75em;
      height: 2em;
      position: relative;
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
      margin-right: 0.15rem;
      bottom: -1px;
      // bottom: 2px;

      .timeline__leg_walk & {
        background-image: url(../images/walk.svg);
      }
      .timeline__leg_bus & {
        background-image: url(../images/bus.svg);
      }
      .timeline__leg_tram & {
        background-image: url(../images/tram.svg);
      }
      .timeline__leg_trolley & {
        background-image: url(../images/trolley.svg);
      }
    }
  }
}
</style>
