<template>
  <div class="point">
    <div
      :class="[
        'point__inputs',
        inputFocus ? 'point__inputs_input-focus' : '',
        loading ? 'point__inputs_loading' : '',
        point.name.primary != '' ? 'point__inputs_filled' : '',
        point.name.secondary != '' && !recording
          ? 'point__inputs_has-secondary'
          : ''
      ]"
    >
      <input
        class="point__input"
        type="text"
        v-model.trim="textInput"
        @focus="onFocus()"
        @blur="onBlur()"
        @input="getPlaces()"
        :placeholder="text"
      />
      <div class="point__loading" v-if="loading">{{ loadingText }}</div>
      <div class="point__secondary">{{ point.name.secondary }}</div>
      <div class="point__voice" v-if="supportsRecognition">
        <div
          :class="[
            'point__voice-button',
            recording ? 'point__voice-button_recording' : ''
          ]"
          @focus="onVoiceFocus()"
          @click="voiceInput()"
          tabindex="0"
          @blur="onVoiceBlur()"
        >
          <Icon file="microphone.svg" />
        </div>
      </div>
      <div class="point__dropdown">
        <Dropdown
          @selected="updatePoint"
          @continue="clear(true)"
          @pressedEnter="this.$emit('pressedEnter')"
          :options="this.places"
          :open="dropdownOpen"
        />
      </div>
    </div>
    <div class="point__buttons">
      <div
        class="point__button point__button_geolocate"
        v-if="state.supportsGeolocation"
        tabindex="0"
        @click="geolocate()"
      >
        Vaša poloha
      </div>
      <div
        class="point__button point__button_map"
        tabindex="0"
        @click="$emit('selectFromMap')"
      >
        Označiť na mape
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import Dropdown from "./Dropdown.vue";
import axios from "axios";

export default {
  name: "Point",
  components: {
    Icon,
    Dropdown
  },
  props: {
    point: Object,
    origin: Boolean,
    state: Object
  },
  data() {
    return {
      supportsRecognition: false,
      recognition: false,
      textInput: "",
      inputFocus: false,
      voiceFocus: false,
      places: [],
      recording: false,
      loading: false,
      loadingText: ""
    };
  },
  computed: {
    text: function() {
      if (this.loading) {
        return "";
      }

      if (this.recording) {
        return "Počúvam...";
      }

      if (this.point.name.primary == "") {
        return this.origin ? "Odkiaľ cestujete?" : "Kam cestujete?";
      }

      return this.point.name.primary;
    },
    dropdownOpen: function() {
      return this.inputFocus || this.voiceFocus;
    }
  },
  methods: {
    geolocate() {
      this.loading = true;
      this.loadingText = "Zisťujem polohu...";
      var self = this;
      navigator.geolocation.getCurrentPosition(
        function(position) {
          self.updateState("allowGeolocation", true);
          localStorage.allowGeolocation = "true";
          self.$emit("updatePoint", {
            coords: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            name: {
              primary: "",
              secondary: ""
            }
          });
        },
        function() {
          // Failure
          self.loadingText = "";
          self.loading = false;
          alert("Nepodarilo sa zistiť Vašu polohu.");
        }
      );
    },
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    initVoiceInput() {
      if (
        window.SpeechRecognition == undefined &&
        window.webkitSpeechRecognition == undefined &&
        window.mozSpeechRecognition == undefined &&
        window.msSpeechRecognition == undefined
      ) {
        return;
      }

      this.supportsRecognition = true;

      this.recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition)();

      this.recognition.continuous = false;
      this.recognition.lang = "sk-SK";
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 1;

      let self = this;

      this.recognition.onresult = function(event) {
        self.recording = false;
        self.textInput = event.results[0][0].transcript;
        self.getPlaces();
      };
      this.recognition.onaudioend = function() {
        self.recognition.stop();
        self.recording = false;
      };
      this.recognition.onend = function() {
        self.recognition.stop();
        self.recording = false;
      };
      this.recognition.onerror = function() {
        self.recognition.stop();
        self.recording = false;
      };
      this.recognition.onnomatch = function() {
        self.recognition.stop();
        self.recording = false;
      };
    },
    voiceInput() {
      if (!this.supportsRecognition || this.recording) {
        return;
      }
      this.recording = true;
      this.recognition.start();
    },
    clear(clearPlaces = false, clearPoint = false) {
      if (clearPlaces) {
        // Without this condition a place could not be selected
        this.places = [];
      }

      if (clearPoint) {
        this.$emit("updatePoint", {
          coords: {
            lat: 0,
            lng: 0
          },
          name: {
            primary: "",
            secondary: ""
          }
        });
      }

      this.textInput = "";
    },
    getPlaces() {
      if (this.textInput.length < 3) {
        this.places = [];
        return;
      }

      axios
        .get("https://api.najdispoj.sk:25517/autocomplete", {
          params: { query: this.textInput }
        })
        .then(response => {
          console.log(response.data);
          if (response.data.query != this.textInput) {
            this.places = [];
          } else {
            this.places = Object.freeze(response.data.places);
          }
        });
    },
    updatePoint(index) {
      this.$emit("updatePoint", this.places[index]);
      this.textInput = "";
      this.places = [];
    },
    onFocus() {
      this.inputFocus = true;
      this.clear(true, true);
    },
    onBlur() {
      this.inputFocus = false;
      this.clear();
    },
    onVoiceFocus() {
      this.voiceFocus = true;
      this.clear(true, true);
    },
    onVoiceBlur() {
      this.voiceFocus = false;
      this.clear();
    }
  },
  watch: {
    point: {
      handler() {
        if (
          this.point.coords.lat != 0 &&
          this.point.coords.lng != 0 &&
          this.point.name.primary == ""
        ) {
          this.loading = true;
          axios
            .get("https://api.najdispoj.sk:25517/reverse", {
              params: {
                coords: this.point.coords
              }
            })
            .then(response => {
              this.loadingText = "";
              this.loading = false;
              this.$emit("updatePoint", {
                coords: this.point.coords,
                name: response.data.name
              });
            });
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initVoiceInput();
  }
};
</script>

<style lang="scss">
.point {
  // border-radius: .75rem;
  // border: 1px solid $c-border-gray;
  // padding: .65rem;
  // padding-top: .5rem;
  margin-bottom: 1.75rem;

  &__inputs {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &_filled input::placeholder {
      font-weight: 700 !important;

      :not(.page_dark) & {
        color: rgba($c-font-black, 0.95);
      }
      .page_dark & {
        color: rgba($c-white, 0.75);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../images/check-circle.svg);
      width: 0;
      height: 0;
      left: 0.75rem;
      opacity: 0;
    }

    &_filled:before {
      width: 1.2rem;
      height: 1.2rem;
      opacity: 1;
      transition: width 0.1s ease-in-out, height 0.1s ease-in-out,
        opacity 0.025s ease-in-out;
    }
  }

  &__input {
    width: 100%;
    background: none;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    padding: 1rem;
    transition: box-shadow 0.1s ease-in-out;

    :not(.page_dark) & {
      color: $c-font-black;
    }
    .page_dark & {
      color: $c-white;
    }

    :not(.page_dark) & {
      background-color: $c-light-gray;
    }
    .page_dark & {
      background-color: $c-black;
    }

    .point__inputs_filled & {
      padding-left: 2.5rem !important;
      // background-color: $c-green-light;
    }

    .point__inputs_has-secondary & {
      padding: 0.5rem 1rem 1.5rem;
    }

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }

    .page_plastic .point__inputs_filled & {
      // border-bottom-color: $c-green-light-side;
    }

    &::placeholder {
      :not(.page_dark) & {
        color: rgba($c-black, 0.4);
      }
      .page_dark & {
        color: rgba($c-white, 0.4);
      }
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $c-yellow;
      border-color: $c-white;

      &::placeholder {
        display: none;
      }
    }
  }

  &__loading {
    position: absolute;
    opacity: 0.55;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-weight: 600;

    &:before {
      content: "";
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../images/loading.svg);
      opacity: 0.65;
      margin-right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;

      // Spinning animation
      animation-name: spin;
      animation-duration: 0.35s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &__secondary {
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    font-size: 0.75rem;
    opacity: 0.5;
    pointer-events: none;
    display: none;

    .point__inputs_filled & {
      left: 2.5rem;
    }

    .point__inputs_has-secondary & {
      display: block;
    }

    // .point__inputs_input-focus &,
    // .point__inputs_loading & {

    // }
  }

  &__voice {
    position: absolute;
    right: 0.5rem;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__voice-button {
    border-radius: 100px;
    padding: 0.5rem;
    transition: border-bottom 0.1s ease-in-out,
      background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    :not(.page_dark) & {
      background-color: $c-light-gray;
    }
    .page_dark & {
      background-color: $c-black;
    }

    &_recording {
      box-shadow: 0 0 1rem -0.1rem $c-red;
      outline: none;

      :not(.page_dark) & {
        background-color: lighten($c-red, 10);
      }
      .page_dark & {
        background-color: lighten($c-red, 10);
      }

      .page_plastic & {
        border-bottom: 2px solid $c-red;
      }

      img {
        opacity: 1 !important;

        filter: invert(1);
      }
    }

    img {
      height: 1.1rem;
      width: 1.1rem;
      transition: filter 0.1s ease-in-out;
      opacity: 0.55;

      .page_dark & {
        filter: invert(1);
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    // background-color: darken($c-primary, 5);
  }

  &__button {
    // flex-grow: 1;
    display: flex;
    padding: 0.65rem 0.85rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 0.85rem;
    font-weight: 700;
    opacity: 0.85;

    &_geolocate {
      color: $c-green;

      :not(.page_dark) & {
        background-color: $c-green-light;
      }
      .page_dark & {
        background-color: $c-green-dark;
      }
    }

    &_map {
      color: $c-blue;

      :not(.page_dark) & {
        background-color: $c-blue-light;
      }
      .page_dark & {
        background-color: $c-blue-dark;
      }
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
