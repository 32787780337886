<template>
  <div class="footer">
    <div class="footer__menu">
      <div class="footer__row">
        <div class="footer__item">
          <div class="footer__link" @click="openModal('about')">O najdispoj.sk</div>
        </div>
        <div class="footer__item">
          <div class="footer__link" @click="openModal('sources')">Zdroje dát</div>
        </div>
        <div class="footer__item">
          <div class="footer__link" @click="openModal('terms')">Podmienky použitia</div>
        </div>
      </div>
      <div class="footer__row footer__row_right">
        <div class="footer__item">
          <div class="footer__link footer__link_ticket" @click="openModal('ticket')">SMS lístok</div>
        </div>
        <div class="footer__item">
          <div class="footer__link footer__link_darkmode" @click="toggleDarkMode">
            {{ darkmodeLabel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    state: Object
  },
  methods: {
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    openModal(modal) {
      this.updateState('openModal', modal);
    },
    toggleDarkMode() {
      this.updateState("darkmode", !this.state.darkmode);
    }
  },
  computed: {
    darkmodeLabel: function() {
      return this.state.darkmode ? "Svetlý režim" : "Tmavý režim";
    }
  }
};
</script>

<style lang="scss">
.footer {
  padding: 1rem 1.25rem;

  :not(.page_dark) & {
    background-color: $c-white;
  }
  .page_dark & {
    background-color: $c-dark-gray;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__row {
    display: flex;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: .75rem;
    }

    &_right {
      justify-content: flex-end;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: .75rem;
    }
  }

  &__link {
    text-decoration: none;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    padding: 0.35rem 0;
    border-radius: 0.5rem;

    // &:hover {
    //   text-decoration: underline;
    // }

    &_darkmode {
      padding: 0.35rem 0.55rem;

      :not(.page_dark) & {
        color: $c-white;
        background-color: $c-gray;
      }
      .page_dark & {
        color: $c-font-black;
        background-color: $c-white;
      }
    }

    &_ticket {
      padding: 0.35rem 0.55rem;

      :not(.page_dark) & {
        color: $c-blue;
        background-color: $c-blue-light;
      }
      .page_dark & {
        color: $c-blue;
        background-color: $c-blue-dark;
      }
    }
  }
}
</style>
