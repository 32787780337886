<template>
  <div class="datetime">
    <div class="datetime__field">
      <div
        class="datetime__arriveby"
        tabindex="0"
        @click="toggleArriveBy"
        @focus="arrivebyFocus = true"
        @blur="arrivebyFocus = false"
      >
        {{ stringifyType() }}
      </div>
    </div>
    <div class="datetime__field">
      <div
        class="datetime__date"
        tabindex="0"
        @focus="dateFocus = true"
        @blur="dateFocus = false"
      >
        {{ stringifyDate() }}
      </div>
      <div class="datetime__dropdown">
        <Dropdown
          @selected="updateDate"
          @pressedEnter="focusTime()"
          :options="this.dates"
          :open="this.dateFocus"
          compact
        />
      </div>
    </div>
    <div class="datetime__field">
      <div class="datetime__time">
        <div class="datetime__time-value">
          {{ stringifyTime() }}
        </div>
        <div class="datetime__timepicker" @click.stop="timeOpen">
          <Timepicker
            :time="state.time"
            :open="timeFocus"
            :triggers="triggers"
            @setTime="updateTime"
            @close="timeClose()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import Timepicker from "./Timepicker.vue";
import dayjs from "dayjs";

export default {
  name: "Datetime",
  components: {
    Dropdown,
    Timepicker
  },
  props: {
    state: Object,
    triggers: Object
  },
  data() {
    return {
      arrivebyFocus: false,
      dateFocus: false,
      timeFocus: false,
      today: dayjs()
    };
  },
  mounted() {
    var self = this;
    document.addEventListener("keydown", function(e) {
      if (e.code == "Space" && self.arrivebyFocus) {
        e.preventDefault();
        self.toggleArriveBy();
      }
    });
    this.popupItem = this.$el;

    this.updateState("date", this.dates[1]);
  },
  computed: {
    dates: function() {
      var dates = [];

      for (var i = -1; i < 7; i++) {
        var date = this.today.add(i, "day");

        var value = date.format("YYYY-MM-DD");
        var name = this.formatDate(date);

        dates.push({
          value: value,
          date: date,
          name: {
            primary: name
          }
        });
      }

      return dates;
    }
  },
  watch: {
    time: function(newVal) {
      if (newVal != "") {
        this.updateState("time", this.time);
      }
    }
  },
  methods: {
    isToday() {
      return this.state.date.date.diff(this.today, "day") == 0;
    },
    stringifyType() {
      return this.state.arriveBy ? "Príchod" : "Odchod";
    },
    stringifyDate() {
      return this.state.date.name.primary;
    },
    stringifyTime() {
      return this.state.time.now && this.isToday()
        ? "Teraz"
        : this.state.time.hours +
            ":" +
            ("0" + this.state.time.minutes).slice(-2);
    },
    toggleArriveBy() {
      this.updateState("arriveBy", !this.state.arriveBy);
    },
    updateState(key, value) {
      this.$emit("updateState", key, value);
    },
    updateTime(time) {
      this.updateState("time", time);
    },
    updateDate(index) {
      this.updateState("date", this.dates[index]);
    },
    focusTime() {
      this.timeOpen();
      this.$nextTick(function() {
        document.getElementsByClassName("timepicker__value_hours")[0].focus();
      });
    },
    formatDate(date) {
      var diff = date.diff(this.today, "day");

      if (diff == -1) {
        return "Včera";
      }

      if (diff == 0) {
        return "Dnes";
      }

      if (diff == 1) {
        return "Zajtra";
      }

      // TODO use UpdateLocale plugin?
      // https://day.js.org/docs/en/customization/weekday-abbreviations
      var localWeekdays = {
        Mon: "Pon",
        Tue: "Uto",
        Wed: "Str",
        Thu: "Štv",
        Fri: "Pia",
        Sat: "Sob",
        Sun: "Ned"
      };
      return localWeekdays[date.format("ddd")] + date.format(" D.M.");
    },

    timeOpen() {
      this.timeFocus = true;
    },

    timeClose() {
      this.timeFocus = false;
    },

    focusHour() {
      document.getElementsByClassName("timepicker__value_hours")[0].focus();
    }
  }
};
</script>

<style lang="scss">
.datetime {
  display: flex;
  margin: 0 -0.25rem;

  &__type {
    // flex-grow: 1;
    // margin-right: .5rem;
  }

  &__field {
    flex-grow: 1;
    flex-basis: 100%;
    padding: 0 0.25rem;
    position: relative;
  }

  &__arriveby {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.75rem 0.9rem;
    width: 100%;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 600;

    :not(.page_dark) & {
      color: rgba($c-font-black, 0.55);
      background-color: $c-light-gray;
    }
    .page_dark & {
      color: rgba($c-white, 0.4);
      background-color: $c-black;
    }

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }
  }

  &__date {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.5rem;
    font-family: $font-family;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem 0.9rem;

    :not(.page_dark) & {
      color: rgba($c-font-black, 0.55);
      background-color: $c-light-gray;
    }
    .page_dark & {
      color: rgba($c-white, 0.4);
      background-color: $c-black;
    }

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }

    option {
      margin: 0.25rem;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
  }

  &__time {
    position: relative;
  }

  &__time-value {
    padding: 0.75rem 0.9rem;
    border-radius: 0.5rem;
    font-weight: 600;

    :not(.page_dark) & {
      color: rgba($c-font-black, 0.55);
      background-color: $c-light-gray;
    }
    .page_dark & {
      color: rgba($c-white, 0.4);
      background-color: $c-black;
    }
  }

  &__timepicker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: none;
    font-family: $font-family;
    font-size: 1rem;
    font-weight: 600;

    :not(.page_dark) & {
      color: rgba($c-font-black, 0.55);
    }
    .page_dark & {
      color: rgba($c-white, 0.4);
    }

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }
  }
}
</style>
